import * as React from "react"
import styled from "@emotion/styled"
import Link from "./Link"
import { translateMessageId } from "../utils/translations"
import { useIntl } from "react-intl"
import Translation from "./Translation"
import MainMobileNav from "./MainMobileNav"
import ButtonLink from "./ButtonLink"
import LogoNUTSVG from "../assets/icon-1.svg"
import LogoSVG from "../assets/logo.svg"

const NavContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
`

const StyledNav = styled.nav`
  height: ${(props) => props.theme.variables.navHeight};
  padding: 1rem 1rem 1rem 0rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
  border-bottom: 1px solid ${(props) => props.theme.colors.text};
`
const NavContent = styled.div`
  display: flex;
  width: 100%;
  max-width: ${(props) => props.theme.breakpoints.xl};
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    align-items: center;
    justify-content: space-between;
  }
`

const InnerContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    display: none;
  }
`
const LeftItems = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`

const RightItems = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`

const MainNavLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 0.8rem;

  font-family: Calibri, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  padding: 0 1rem;

  color: ${(props) => props.theme.colors.text};
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
  }
`

const Logo = styled.span`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 4rem;
  margin-bottom: 0.5rem;

  font-family: Calibri, sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 2.6rem;
  padding: 0 1rem;

  font-style: italic;
  opacity: 0.75;

  color: ${(props) => props.theme.colors.text};
`

export interface IProps {}

const MainNav: React.FC<IProps> = () => {
  const intl = useIntl()
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const handleMenuToggle = (item?: "menu"): void => {
    if (item === "menu") {
      setIsMenuOpen(!isMenuOpen)
    } else {
      setIsMenuOpen(false)
    }
  }

  return (
    <NavContainer>
      <StyledNav aria-label={translateMessageId("nav-primary", intl)}>
        <NavContent>
          <Logo>
            <LogoNUTSVG width="1.2em" height="1.2em" />
            <Translation id="utcash" />
            {/* <LogoSVG width="1.3em" height="1.27em" /> */}
            <LogoSVG width="1.3em" height="1.37em" />
          </Logo>
          {/* Desktop */}
          <InnerContent>
            <LeftItems>
              <MainNavLink href="/">
                <Translation id="home" />
              </MainNavLink>
              <MainNavLink href="#about">
                <Translation id="about" />
              </MainNavLink>
              <MainNavLink href="#how-to-buy">
                <Translation id="how-to-buy" />
              </MainNavLink>
              <MainNavLink href="#tokenomics">
                <Translation id="tokenomics" />
              </MainNavLink>
              <MainNavLink href="#staking">
                <Translation id="staking" />
              </MainNavLink>
            </LeftItems>
            <RightItems>
              <ButtonLink
                variant="outline"
                href="https://app.uniswap.org/swap?outputCurrency=0xd105c45BCC7211F847Ae73B187A41b7D8184aDE2"
                hideArrow={true}
                fontWeight={"bold"}
                mr="1rem"
              >
                <Translation id="buy-now" />
              </ButtonLink>
            </RightItems>
          </InnerContent>

          {/* Mobile */}
          <MainMobileNav
            isMenuOpen={isMenuOpen}
            toggleMenu={handleMenuToggle}
          />
        </NavContent>
      </StyledNav>
    </NavContainer>
  )
}

export default MainNav
